import { Form, Input, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { DateHelper } from '../../../../models/helpers/DateHelper'

export const TimelineItemCard = ({
  fieldKey,
  restField,
  name,
  isOptional,
  onRemove,
  description,
}) => {
  const [descriptionContent, setDescriptionContent] = useState(description)
  const YEARS_RANGE = DateHelper.getYearsRange()

  return (
    <div key={fieldKey} className="brand-item">
      <div className="brand-logo-upload">
        <div className="d-flex justify-content-between align-items-center">
          <div className="row w-100">
            <div className="col-12 col-lg-6 order-1">
              <Form.Item
                {...restField}
                name={[name, 'year']}
                rules={[{ required: true, message: 'Field is required' }]}
                label="Date"
              >
                <Select placeholder="Year">
                  {YEARS_RANGE.map((y) => (
                    <Select.Option value={y}>{y}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-2">
              <div className="d-flex justify-content-between align-items-start">
                <div style={{ width: '100%', flex: '0 0 100%' }}>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    rules={[{ required: true, message: 'Field is required' }]}
                    label="Description"
                  >
                    <Input.TextArea
                      placeholder="Enter Description..."
                      className="w-100"
                      value={descriptionContent}
                      maxLength={500}
                      rows={5}
                      onChange={(e) => setDescriptionContent(e.target.value)}
                    />
                  </Form.Item>
                  <p className="mb-0 text-sm-end">
                    {descriptionContent ? descriptionContent.length : '0'} / 500
                  </p>
                </div>
                <div className="text-end">
                  {isOptional && (
                    <Form.Item className="mb-0 timeline-remove">
                      <DeleteOutlined
                        className="danger-color"
                        onClick={() => onRemove(name)}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
