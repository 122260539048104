import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import MainLayout from './MainLayout'
import AuthLayout from './AuthLayout'
import MarketplaceLayout from './MarketplaceLayout'

import { loginFromAPIToken } from '../services'

import { history } from '../index'
import { Skeleton } from 'antd'

const mapStateToProps = ({ user }) => ({ user })

const Layouts = {
  login: AuthLayout,
  main: MainLayout,
  marketplace: MarketplaceLayout,
  dynamicPrinting: React.Fragment,
}

const waitingForApprovalUrl = '/secured/verification/success'

const IndexLayout = ({ user, children, location: { pathname, search } }) => {
  // Layout Rendering
  const getLayout = () => {
    if (/^\/secured(?=\/|$)/i.test(pathname)) return 'login'
    if (/^\/marketplace(?=\/|$)/i.test(pathname)) return 'marketplace'
    if (/^\/profile\/dynamic-printing\/editor/i.test(pathname)) return 'dynamicPrinting'
    return 'main'
  }

  const Container = Layouts[getLayout()] // type
  const isUserAuthorized = user.authorized
  const userInfo = user.user
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'login'

  const BootstrappedLayout = () => {
    // Admin login
    const params = new URLSearchParams(search)
    const adminAccessToken = params.get('adminAccessToken')
    const destination = params.get('destination')
    if (adminAccessToken && adminAccessToken.length > 10) {
      loginFromAPIToken(adminAccessToken, () => {
        if (destination) {
          switch (destination) {
            case "Incomplete":
            case "All-Products":
              history.push("/products/list/all")
              break
            case "Company-Identity":
              history.push("/dashboard/company-identity")
              break
            case "Dynamic-Printing":
              history.push("/profile/dynamic-printing")
              break
            case 'on-boarding/seller/profile-info':
                history.push("/secured/verification/seller/profile-info")
                break
            default:
              history.push("/dashboard")
          }
        } else {
          history.push("/dashboard")
        }
        window.location.reload()
      })
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && getLayout() !== 'marketplace' && !isUserAuthorized) {
      return <Redirect to="/secured/login" />
    }

    if (
      pathname === waitingForApprovalUrl &&
      isUserAuthorized &&
      userInfo.get_user_company &&
      userInfo.get_user_company.status === 'Approved'
    ) {
      return <Redirect to="/" />
    }

    return (
      <Container>
        {React.cloneElement(children, {
          isSeller: userInfo.is_seller,
          isSellerVerified: userInfo.is_seller_verified,
        })}
      </Container>
    )
  }

  return (
    <>
      <HelmetProvider>
        <Helmet titleTemplate="%s | TradeToWorld" title="TradeToWorld" />
        <Suspense fallback={<Skeleton />}>{BootstrappedLayout()}</Suspense>
      </HelmetProvider>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(IndexLayout))
