import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button, message } from 'antd'

import loginImg from '../../assets/onboarding/login.svg'

import actions from '../../redux/user/actions'
import { Link } from 'react-router-dom'

const LoginPage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const onFormSuccess = async (values) => {
    const { email, password } = values
    dispatch({
      type: actions.LOGIN,
      payload: {
        email,
        password,
      },
    })
  }

  const onFormFailed = () => {
    message.error({ content: 'Check the form and try again please' })
  }

  useEffect(() => {
    window.location.assign('https://editorial.tradeto.world/login')
  }, [])

  return (
    <>
      <Helmet title="Login" />
      <div className="secured-login">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-5 order-2 order-lg-1">
              <div className="login__form">
                <h1>Login</h1>
                <p>
                  Don't have an account?
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://tradeto.world/#/secured/register"
                  >
                    &nbsp;Sign Up
                  </a>
                </p>
                <Form
                  onFinish={onFormSuccess}
                  onFinishFailed={onFormFailed}
                  layout="vertical"
                  hideRequiredMark
                  className="mb-0"
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="email" placeholder="Email*" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Field is required' }]}
                  >
                    <Input type="password" placeholder="Password*" />
                  </Form.Item>
                  <Link
                    className="login__form__password-reset"
                    to="/secured/forgot-password"
                  >
                    Forgot your Password?
                  </Link>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="w-100 ant-btn-ttu"
                      htmlType="submit"
                      loading={user.loading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 mb-4 mb-lg-0">
              <div className="login__image">
                <img src={loginImg} alt="TTW Login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
