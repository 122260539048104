export class DateHelper {
  static formatToLocaleDate = (date, locale = 'en-US', config = null) => {
    if (config) {
      return new Date(Date.parse(date)).toLocaleDateString(locale, config)
    }
    return new Date(Date.parse(date)).toLocaleDateString()
  }

  static replaceSlashes = (date, replacement) => date.replace(/\//g, replacement)

  static replaceSlashesWithDots = (date) => DateHelper.replaceSlashes(date, '.')

  static formatToLocaleDateWithDots = (date) =>
    this.replaceSlashesWithDots(this.formatToLocaleDate(date))

  static getCurrentYear = () => new Date().getFullYear()

  static getYearsRange = (limit = 1800) => {
    const range = []

    for (let i = DateHelper.getCurrentYear(); i >= limit; i--) {
      range.push(i)
    }

    return range
  }
}
