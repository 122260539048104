import _ from 'lodash'
import { v4 } from 'uuid'
import React, { useState } from 'react'
import { Button, Form, Select, AutoComplete } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import MEASURES from '../../../data/measures'

import { updateProductWeightAndAttribute } from '../../../util/functions/helpers/productUploadHelpers'

import axiosRequest from '../../../hooks/useAxiosRequest'

/**
 * @description AttributesSelect component is a form that allows the user to input the product's attributes.
 * @category Presentational Components/Pages/ProductUpload
 * @namespace Pages - ProductUpload - AttributesSelect
 *
 * @param {object} props
 * @param {object} props.product - The product object
 * @param {function} props.updateProduct - The function to update the product object
 * @param {Array} props.attributes - The attributes array
 * @param {function} props.setProductData - The function to set the product object
 *
 * @method onAttributeSelect - The function to select the attribute from the list
 * @method onMeasureSelect - The function to select the measure from the list
 * @method onValueChange - The function to change the value of the attribute
 * @method handleUpdateWeight - The function to update the weight of the product
 *
 * @returns {JSX.Element} The AttributesSelect component
 *
 * @example
 * <AttributesSelect
 * product={product}
 * updateProduct={updateProduct}
 * attributes={attributes}
 * setProductData={setProductData}
 * />
 *
 * @requires antd
 * @requires lodash
 * @requires react
 * @requires uuid
 *
 * @version 0.1.1
 * @since 0.1.0
 * @author Evgny Tulikov
 *
 * @todo Add PropTypes
 * @todo Refactor the component to use hooks
 * @todo Refactor the component to use TypeScript
 * @todo Refactor the component for better modularity
 * @todo Create tests
 * @todo Create stories
 *
 */
const AttributesSelect = ({
  product,
  updateProduct,
  attributes,
  setProductData = () => null,
}) => {
  const [autocomplete, setAutoComplete] = useState([])

  const handleUpdateWeight = (key, value) => {
    setProductData((prev) => ({
      ...updateProductWeightAndAttribute(prev, key, value),
    }))
  }

  const onAttributeSelect = (index, value) => {
    const newAttributes = product.attributes
    newAttributes[index].key = value.key
    newAttributes[index].id = value.value
    newAttributes[index].label = value.label
    const findAttr = _.find(attributes, (data) => data.id === value.value)
    newAttributes[index].type = findAttr.type
    newAttributes[index].measure = ''
    updateProduct('attributes', newAttributes)
  }

  const onMeasureSelect = (index, value) => {
    const newAttributes = product.attributes
    newAttributes[index].measure = value
    updateProduct('attributes', newAttributes)
  }

  const onValueChange = (index, value) => {
    const newAttributes = product.attributes
    newAttributes[index].value = value
    axiosRequest(
      `/api/v1/products/attributes/options/autocomplete?attribute=${newAttributes[index].id}&q=${value}`,
      null,
      'get',
      true,
    ).then((res) => {
      if (res.data.success) {
        setAutoComplete(res.data.options)
      }
    })
    updateProduct('attributes', newAttributes)
  }

  return (
    <div className="verification-layout col-12">
      <Form.Item className="add-unit mb-2">
        <Button
          type="dashed"
          onClick={() => {
            let attributes = product.attributes
            attributes.push({
              uuid: v4(),
              key: v4(),
              id: '',
              value: '',
              type: '',
              measure: '',
              label: '',
            })
            updateProduct('attributes', attributes)
          }}
          block
          icon={<PlusOutlined />}
          style={{ background: 'transparent' }}
        >
          Add Attribute
        </Button>
      </Form.Item>
      <div className="row">
        {product.attributes && product.attributes.length > 0
          ? product.attributes.map((attribute, index) => (
              <div key={attribute.id} className="col-12 col-lg-6">
                <div className="company-units mb-4" style={{ background: '#FFF' }}>
                  <div className="company-units__controls mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div />
                      <div>
                        <Form.Item className="mb-0">
                          <DeleteOutlined
                            className="danger-color"
                            onClick={() => {
                              let newAttributes = product.attributes
                              newAttributes.splice(index, 1)
                              updateProduct('attributes', [...newAttributes])
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        label="Search Attribute"
                        rules={[
                          {
                            required: true,
                            message: 'Field is required',
                          },
                        ]}
                      >
                        <Select
                          labelInValue
                          showSearch
                          optionFilterProp="children"
                          value={{
                            value: product.attributes[index].id,
                            label: product.attributes[index].label,
                          }}
                          onSelect={(value) => onAttributeSelect(index, value)}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {attributes.length > 0 &&
                            attributes.map((attribute) => {
                              // FIXME: Deprecated -> Remove this
                              // if (attribute.name === 'Weight') {
                              //   return null
                              // }

                              return (
                                <Select.Option value={attribute.id} key={attribute.id}>
                                  {attribute.name}
                                </Select.Option>
                              )
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Form.Item
                            label="Value"
                            rules={[
                              {
                                required: true,
                                message: 'Field is required',
                              },
                            ]}
                          >
                            <AutoComplete
                              value={product.attributes[index].value}
                              placeholder="Input attribute value"
                              onChange={(value) =>
                                product.attributes[index].id === 202
                                  ? handleUpdateWeight('value', value)
                                  : onValueChange(index, value)
                              }
                              onSelect={() => {
                                setAutoComplete([])
                              }}
                              onBlur={() => {
                                setAutoComplete([])
                              }}
                              onFocus={() => {
                                axiosRequest(
                                  `/api/v1/products/attributes/options/autocomplete?attribute=${product.attributes[index].id}`,
                                  null,
                                  'get',
                                  true,
                                ).then((res) => {
                                  if (res.data.success) {
                                    setAutoComplete(res.data.options)
                                  }
                                })
                              }}
                            >
                              {autocomplete.map((option) => {
                                return (
                                  <AutoComplete.Option
                                    value={option.option}
                                    key={option.id}
                                  >
                                    {option.option}
                                  </AutoComplete.Option>
                                )
                              })}
                            </AutoComplete>
                          </Form.Item>
                        </div>
                        {product.attributes[index].type &&
                        MEASURES[product.attributes[index].type] ? (
                          <div className="col-12 col-md-6">
                            <Form.Item
                              label="Measure"
                              rules={[
                                {
                                  required: false,
                                  message: 'Field is required',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={product.attributes[index].measure || ''}
                                onSelect={(value) =>
                                  product.attributes[index].id === 202
                                    ? handleUpdateWeight('measure', value)
                                    : onMeasureSelect(index, value)
                                }
                              >
                                {MEASURES[product.attributes[index].type].map(
                                  (measure) => (
                                    <Select.Option
                                      value={measure.code || measure}
                                      key={measure.code || measure}
                                    >
                                      {measure.name || measure}
                                    </Select.Option>
                                  ),
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default AttributesSelect
